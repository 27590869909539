import 'chartjs-adapter-moment'
import 'moment/locale/ru'

import React, {Suspense, useEffect} from 'react'
import Header from "$components/Header"
import Popup from "$components/Popup"
import Loader from "$components/Loader"
import OfferPopup from "$components/OfferPopup"
import OfferWidget from "$components/OfferWidget"
import MainBanner from "$components/MainBanner"
import {Outlet, useLocation} from "react-router-dom"
import { scrollToElementById } from "$utils/scrollToElementById"
import useStore from "$hooks/useStore"
import UserStore from "$stores/UserStore"

const AppLayout = ({ isInitialized }) => {
  const { hash } = useLocation();
  const [state] = useStore(UserStore, (store) => ({
    popup: store.popup as any,
    offer: store.offer,
  }))

  useEffect(() => {
    if (isInitialized && hash) {
      scrollToElementById(hash)
    }
  }, [isInitialized, hash])

  useEffect(() => {
    if (state?.popup.isOpen) {
      console.log('OPEN')
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [state?.popup?.isOpen])

  useEffect(() => {
    setTimeout(() => {
      UserStore.mergeOntoState({
        offer: {
          ...UserStore.state.offer,
          isShowThird: true,
        }
      })
    }, 60000)



    if (window.innerWidth < 920) {
      UserStore.mergeOntoState({
        offer: {
          ...UserStore.state.offer,
          isShowSecond: true,
        }
      })
    } else {
      setTimeout(() => {
        UserStore.mergeOntoState({
          offer: {
            ...UserStore.state.offer,
            isShowSecond: true,
          }
        })
      }, 30000)
    }
  }, [])

  return (
    <>
      {state.offer.isShowFirst && (
        <MainBanner />
      )}
      <Header />
      <Popup />
      <Suspense
        fallback={(
          <div className='loader-glob'>
            <Loader/>
          </div>
        )}
      >
        <Outlet/>
      </Suspense>
      {state.offer.isShowSecond && (
        <OfferWidget />
      )}
      {state.offer.isShowThird && (
        <OfferPopup />
      )}
    </>
  )
}

export default AppLayout
